<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <RedirectManagementForm
          :dialog-props="dialogProps"
          :redirect-data="redirectData" />
    </template>
  </CoreDialog>
</template>

<script>
  import RedirectManagementForm from "../../forms/RedirectManagementForm/RedirectManagementForm"
  import { Redirect } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "RedirectManagementDialog",
    components: {
      RedirectManagementForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      redirectData() {
        return Redirect.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.redirectData) {
          return `${startCase(this.mode)}ing Redirect: ${this.redirectData.fromUrl} > ${this.redirectData.toUrl} (ID: ${this.id})`
        }

        return "Create New Redirect"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchRedirect()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchRedirect() {
        this.isLoading = true

        await Redirect.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>