import RedirectManagementTableView from "./views/RedirectManagementTableView"

const routes = [
  {
    path: "",
    name: "redirectManagement-list-view",
    component: RedirectManagementTableView
  }
]

export default routes