<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.0"
      x="0px"
      y="0px"
      :fill="color"
      class="svg-icon"
      viewBox="0 0 100 125"
      enable-background="new 0 0 100 100"
      xml:space="preserve"><g><path d="M76.131,22.886c-0.221,0-0.436,0.007-0.646,0.021c-2.299-4.389-6.879-7.186-11.869-7.186   c-7.129,0-12.977,5.605-13.369,12.643c-0.61-0.162-1.239-0.245-1.867-0.245c-4.002,0-7.258,3.256-7.258,7.258   c0,3.996,3.255,7.249,7.258,7.251h27.748c0.023,0,0.045,0,0.066-0.002C81.607,42.592,86,38.177,86,32.756   C86,27.314,81.572,22.886,76.131,22.886z M76.131,40.626c-0.021,0-0.043,0-0.063,0.002l-27.689-0.002   c-2.899,0-5.258-2.355-5.258-5.25c0-2.899,2.359-5.258,5.258-5.258c0.83,0,1.664,0.201,2.413,0.582   c0.319,0.163,0.7,0.141,1.001-0.054c0.301-0.196,0.473-0.538,0.451-0.896c-0.014-0.213-0.02-0.418-0.02-0.632   c0-6.285,5.109-11.397,11.391-11.397c4.451,0,8.521,2.618,10.369,6.669c0.186,0.407,0.613,0.638,1.061,0.574   c0.336-0.051,0.701-0.077,1.086-0.077c4.338,0,7.869,3.53,7.869,7.87S80.469,40.626,76.131,40.626z" /><path
          d="M66.904,69.685V49.234c0-0.081,0-0.217-0.033-0.374c-0.111-0.541-0.646-0.906-1.184-0.792   c-0.541,0.111-0.895,0.625-0.783,1.166v19.47H16v-19.47c0-2.956,2.405-5.361,5.361-5.361h3.927h9.519c0.552,0,1-0.448,1-1   s-0.448-1-1-1h-9.519h-3.927c-4.059,0-7.361,3.302-7.361,7.361v21.955c0,4.06,3.302,7.362,7.361,7.362h18.093v3.729h-9.393   c-0.552,0-1,0.447-1,1s0.448,1,1,1h20.785c0.553,0,1-0.447,1-1s-0.447-1-1-1h-9.393v-3.729h18.086c4.061,0,7.363-3.303,7.363-7.362   v-1.465c0-0.007,0.004-0.013,0.004-0.02S66.904,69.691,66.904,69.685z M59.541,76.551H21.362c-2.956,0-5.361-2.405-5.361-5.362   v-0.484h48.904v0.484C64.904,74.146,62.498,76.551,59.541,76.551z" /><path
          d="M34.654,58.923c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l6.5,6.5   c0.002,0.002,0.002,0.004,0.004,0.006c0.032,0.032,0.073,0.045,0.108,0.071c0.069,0.054,0.135,0.11,0.216,0.145   c0.123,0.051,0.252,0.077,0.383,0.077c0,0,0,0,0.001,0s0,0,0.001,0c0.13,0,0.26-0.026,0.382-0.077   c0.125-0.052,0.236-0.128,0.33-0.223l6.503-6.503c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-4.801,4.801V51.783   c0-0.553-0.448-1-1-1s-1,0.447-1,1v11.938L34.654,58.923z" /></g>
  </svg>
</template>

<script>
export default {
  name: "DataImporterIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>
