import UserManagement from "@/Modules/UserManagement"
// import AppointmentNotesManagement from "@/Modules/AppointmentNotesManagement"

// import ClientManagement from "@/Modules/ClientManagement"
// import PatientManagement from "@/Modules/PatientManagement"
// import OrderManagement from "@/Modules/OrderManagement"
// import CaneToadProductManagement from "@/Modules/CaneToadProductManagement";
// import BookingManagement from "@/Modules/BookingManagement"
// import LocationManagement from "@/Modules/LocationsManagement"
// import GeneralPractitionersManagement from "@/Modules/GeneralPractitionersManagement"

// import ClientAlbums from "@/Modules/ClientAlbums"
// import ProductManagement from "@/Modules/ProductManagement"
// import ProductCategoryManagement from "@/Modules/ProductCategoryManagement";
import CspConfiguration from "@/Modules/CspConfiguration";
import RedirectManagement from "@/Modules/RedirectManagement";
import SitePreferences from "@/Modules/SitePreferences";
import SiteManagement from "@/Modules/SiteManagement";
// import PointOfSale from "@/Modules/PointOfSale"
import SystemSettings from "@/Modules/SystemSettings"

export default [
  // PatientManagement,
  // BookingManagement,
  // PointOfSale,
  // OrderManagement,
  // ClientAlbums,
  // CaneToadProductManagement,
  // ProductCategoryManagement,
    CspConfiguration,
    RedirectManagement,
    SitePreferences,
    SiteManagement,
  // AppointmentNotesManagement,
  // GeneralPractitionersManagement,
  // LocationManagement,
  UserManagement,
  SystemSettings
]
