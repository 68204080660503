export default {
  fields: {
    siteFeatures: {
      specialOrder: {
        width: 24,
        type: "switch",
        label: "Enable Special Orders",
      },
      internationalShipping: {
        width: 24,
        type: "switch",
        label: "Enable International Shipping",
      },
      engraving: {
        width: 24,
        type: "switch",
        label: "Enable Engravings",
      },
      clickAndCollect: {
        width: 24,
        type: "switch",
        label: "Enable Click & Collect",
      },
    },
    cacheSettings: {
      redisCacheLifetime: {
        type: "slider",
        props: {
          step: 10,
          'show-stops': true,
          marks: {
            15: "15'",
            30: "30'",
            60: "60'",
            120: "120'",
            180: "180'",
            240: "240'",
          },
          min: 0,
          max: 240,
        },
        step: 2,
        label: "Redis Cache Lifetime (minutes)",
        placeholder: "ie. john.doe@example.org",
        validation: [{required: false, message: "Please enter a valid email", trigger: "blur"}]
      },
      purgeRedisCache: {
        width: 24,
        type: "switch",
        label: "Purge Redis Cache",
      }
    }
  }
}
