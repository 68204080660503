import RedirectManagementStatusColumn from "./RedirectManagementStatusColumn";
import RedirectManagementActiveColumn from "./RedirectManagementActiveColumn";

const defaultColumns = [
    {
      prop: "site",
      label: "Site"
    },
    {
      prop: "fromUrl",
      label: "From",
    },
    {
      prop: "toUrl",
      label: "To",
    },
    {
      prop: "status",
      label: "Status",
      Component: RedirectManagementStatusColumn
    },
    {
      prop: "active",
      label: "Active",
      Component: RedirectManagementActiveColumn
    }
  ]

export default defaultColumns