import SitePreferencesManagementView from "./views/SitePreferencesManagementView"

const routes = [
  {
    path: "",
    name: "sitePreferences-list-view",
    component: SitePreferencesManagementView
  },
  {
    path: "site/:siteId",
    name: "sitePreferences-list-view",
    component: SitePreferencesManagementView
  }
]

export default routes