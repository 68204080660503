<template>
  <div>
    <core-data-table-v2
        :model="redirectModel"
        :columns="columns"
        table-name="redirectManagement-management"
        :searches-and-filters="filters"
        v-on="$listeners" />
  </div>
</template>

<script>
import { Redirect } from "@/Modules/OdysseyModels"
import columns from "../components/RedirectManagementTableView/columns"
import filterConfig from "../components/RedirectManagementTableView/filterConfig.js";
export default {
  name: "RedirectManagementTableView",
  data() {
    return {
      filters: filterConfig
    }
  },
  computed: {
    redirectModel() {
      return Redirect
    },
    columns() {
      return columns
    }
  }
}
</script>
