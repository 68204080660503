<template>
  <fragment>
    <transition-group name="fade">
      <template v-if="isLoading">
        <el-empty
            key="empty-state"
            description="Loading Site Preferences ..."
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" />
      </template>
      <template v-else>
        <CoreScreen
            key="core-screen"
            card
            class="sitePreferences-wrapper"
            header="Site Preferences"
            icon="SitePreferencesIcon"
            :tabs="tabs"
            :create-button="{ label: 'Create SitePreferences', onCreate: createSitePreferences }">
          <div
              v-if="selectedSite"
              class="site-header">
            <h3>{{ selectedSite.title }} </h3>
            <span class="italic">{{ selectedSite.url }}</span>
          </div>
          <SitePreferencesForm
              :key="selectedSiteId"
              :site-preference-data="sitePreferencesData"
              :dialog-props="dialogProps" />
        </CoreScreen>
      </template>
    </transition-group>
  </fragment>
</template>

<script>
import {SitePreference, Site} from "@/Modules/OdysseyModels"
import SitePreferencesForm from "./forms/SitePreferencesForm/SitePreferencesForm";
export default {
  name: "SitePreferences",
  components: {
    SitePreferencesForm
  },
  data() {
    return {
      isLoading: false,
      dialogProps: {
        mode: "edit",
        closeDialog: this.closeDialog
      }
    }
  },
  computed: {
    sitePreferencesData() {
     return SitePreference.find(this.selectedSiteId)
    },
    selectedSiteId() {
      if (this.$route.params.siteId) {
        return this.$route.params.siteId
      }
      return 1
    },
    selectedSite() {
      return Site.find(this.selectedSiteId)
    },
    sites() {
      return Site.all()
    },
    tabs() {
      return this.sites.map(site => ({
        label: site.title,
        to: { path: `/SitePreferences/site/${site.id}`}
      }))
    }
  },
  watch: {
    selectedSiteId() {
      this.fetchSitePreferences()
    }
  },
  async created() {
    this.loading = true
    if (Site.all().length <= 0) {
      await Site.api().fetchAll()
    }
    this.isLoading = false
    await this.fetchSitePreferences()
  },
  methods: {
    async fetchSitePreferences() {
      this.isLoading = true

      await SitePreference.api().fetchById(this.selectedSiteId)

      this.isLoading = false
    },
    createSitePreferences() {
      new SitePreference().Actions.openSitePreferenceDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.site-header {
  padding: 2em;
  margin: -1em -1em 1em -1em;
  background: var(--main-light-grey);
}
  span.italic {
    font-weight: normal;
    font-style: italic;
  }
</style>
