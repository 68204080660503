import CspConfiguration from "./CspConfiguration"
import CspConfigurationIcon from "./components/CspConfigurationIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const CspConfigurationModule = {
  moduleGrouping: "Site Management",
  name: "CspConfiguration",
  menuName: "CSP Configuration",
  component: CspConfiguration,
  path: "CspConfiguration",
  icon: CspConfigurationIcon,
  iconTitle: "Configure your CSP here",
  iconDescription: "View CspConfiguration data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("CspConfiguration", CspConfiguration)
    Vue.component("CspConfigurationIcon", CspConfigurationIcon)
  }
}
export default CspConfigurationModule
