<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <CspConfigurationForm
          :dialog-props="dialogProps"
          :form-data="cspConfigurationData" />
    </template>
  </CoreDialog>
</template>

<script>
  import CspConfigurationForm from "../../forms/CspConfigurationForm/CspConfigurationForm"
  import { CspConfiguration } from "@/Modules/OdysseyModels"
  import startCase from "lodash/startCase"

  export default {
    name: "CspConfigurationDialog",
    components: {
      CspConfigurationForm
    },
    props: {
      id: { type: [String, Number], default: null },
      closeDialog: { type: Function, required: true },
      dialogProps: { type: Object, required: true },
    },
    data() {
      return {
        isLoading: true
      }
    },
    computed: {
      cspConfigurationData() {
        return CspConfiguration.find(this.id)
      },
      mode() {
        return this.dialogProps.mode
      },
      title() {
        if (this.cspConfigurationData) {
          return `${startCase(this.mode)}ing CSP Rule for ${this.cspConfigurationData.site} (ID: ${this.id})`
        }

        return "Create New CSP Rule"
      }
    },
    mounted() {
      if (this.id) {
        this.fetchCspConfiguration()
      } else {
        this.isLoading = false
      }
    },
    methods: {
      async fetchCspConfiguration() {
        this.isLoading = true

        await CspConfiguration.api().fetchById(this.id)

        this.isLoading = false
      }
    }
  }
</script>