const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Rule",
    options: [
      {
        label: "Rule, Violation, Notes",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.rule",
        inputType: "text",
        props: {
          placeholder: "Search by Rule, Violation or Notes"
        }
      },
      {
        label: "Rule Status",
        inputType: "select",
        queryType: "search",
        urlKey: "filter.status",
        props: {
          options: [
            {label: "New", value: "new"},
            {label: "Under review", value: "under review"},
            {label: "Published", value: "published"},
            {label: "Unpublished", value: "unpublished"},
          ],
          placeholder: "Filter by Status"
        }
      },
      {
        label: "Creation Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      },
      {
        label: "Update Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.updatedAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Update Date"
        }
      },
    ]
  },
]