<template>
  <fragment>
    <CoreScreen
        card
        class="cspConfiguration-wrapper"
        header="CSP Configuration"
        icon="CspConfigurationIcon"
        :create-button="actionButtons">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { CspConfiguration } from "@/Modules/OdysseyModels"
export default {
  name: "CspConfiguration",
  computed: {
    actionButtons() {
      return [
        { label: 'Create CSP Rule', onCreate: this.createCspConfiguration },
        { label: 'Show Current CSP Rules', onCreate: this.showCurrentCSPConfiguration }
      ]
    }
  },
  methods: {
    createCspConfiguration() {
      new CspConfiguration().Actions.openCspConfigurationDialog()
    },
    showCurrentCSPConfiguration() {
      new CspConfiguration().Actions.showCurrentCSPConfiguration()
    }
  }
}
</script>
