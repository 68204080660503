import RedirectManagement from "./RedirectManagement"
import RedirectManagementIcon from "./components/RedirectManagementIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const RedirectManagementModule = {
  moduleGrouping: "Site Management",
  name: "RedirectManagement",
  menuName: "Redirects",
  component: RedirectManagement,
  path: "RedirectManagement",
  icon: RedirectManagementIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View RedirectManagement data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("RedirectManagement", RedirectManagement)
    Vue.component("RedirectManagementIcon", RedirectManagementIcon)
  }
}
export default RedirectManagementModule
