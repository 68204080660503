import SitePreferences from "./SitePreferences"
import SitePreferencesIcon from "./components/SitePreferencesIcon"

import dialogs from "./dialogs"
import features from "./features"
import routes from "./routes"

const SitePreferencesModule = {
  moduleGrouping: "Site Management",
  name: "Site Preferences",
  menuName: "Site Preferences",
  component: SitePreferences,
  path: "SitePreferences",
  icon: SitePreferencesIcon,
  iconTitle: "Do you want to know more about your business?",
  iconDescription: "View SitePreferences data here",
  enabled: true,
  features,
  dialogs,
  children: routes,
  registerComponents: Vue => {
    Vue.component("SitePreferences", SitePreferences)
    Vue.component("SitePreferencesIcon", SitePreferencesIcon)
  }
}
export default SitePreferencesModule
