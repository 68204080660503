export default {
  fields: {
    RedirectDetails: {
      site: {
        type: "entity-select",
        props: {
          entity: "Site",
          filterable: true,
          clearable: true,
        },
        label: "Select Site",
        value: null,
        validation: [{required: true, message: "Please select a site", trigger: "blur"}]
      },
      nothing: {
        type: "placeholder"
      },
      fromUrl: {
        type: "text",
        value: null,
        label: "Current URL / Path",
        placeholder: "",
        validation: [{required: true, message: "Please enter a path", trigger: "blur"}]
      },
      toUrl: {
        type: "text",
        value: null,
        label: "New URL / Path",
        placeholder: "",
        validation: [{required: true, message: "Please enter a path", trigger: "blur"}]
      },
      status: {
        type: "select",
        props: {
          filterable: true
        },
        value: null,
        label: "What is the current status of this rule?",
        options: [
          {label: "New", value: "new"},
          {label: "Under Review", value: "under review"},
          {label: "Published", value: "published"},
        ]
      },
      active: {
        type: "switch",
        value: null,
        label: "Activate/Deactivate Redirect",
      }
    },

  }
}
