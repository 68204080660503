<template>
  <div class="group-column">
    <template v-if="scope.row.cspType">
      <template v-for="group in scope.row.cspType">
        <el-tag
            :key="`group-${group}`"
            type="info"
            size="small"
            disable-transitions>
          {{ group }}
        </el-tag> &nbsp;
      </template>
    </template>
  </div>
</template>
<script>
export default {
  name: "CspConfigurationGroupColumn",
  filters: {
    spaceUnderscore(string) {
      return string.replace("_", " ")
    }
  },
  props: {
    scope: {
      type: Object,
      required: true
    }
  },
  computed: {
    activeColor() {
      if (this.scope.row.active === "1") {
        return "success"
      }
      return "danger"
    },
  },
}
</script>

<style lang="scss" scoped>

.status-column {
  margin: 0 auto;
  .el-tag {
    min-width: 90%;
    margin: auto;
    text-align: center;
    box-shadow: var(--box-shadow-container-light);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>
