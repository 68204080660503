<template>
  <svg
      version="1.1"
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon"
      :fill="color">
    <g fill-rule="evenodd">
      <path d="m418.77 121.83h-313.78c-3.625 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h313.78c3.6211 0 6.5625 2.9375 6.5625 6.5625s-2.9414 6.5625-6.5625 6.5625z" />
      <path d="m144.17 81.477c-1.7891 0-3.2383 1.4492-3.2383 3.2305s1.4453 3.2305 3.2344 3.2305c1.7773 0 3.2227-1.4492 3.2227-3.2305s-1.4453-3.2305-3.2227-3.2305zm0 19.586c-9.0234 0-16.363-7.3359-16.363-16.355s7.3359-16.355 16.359-16.355c9.0117 0 16.348 7.3359 16.348 16.355s-7.3359 16.355-16.348 16.355z" />
      <path d="m182.34 81.477c-1.7773 0-3.2344 1.4492-3.2344 3.2305s1.457 3.2305 3.2344 3.2305c1.7773 0 3.2344-1.4492 3.2344-3.2305s-1.457-3.2305-3.2344-3.2305zm0 19.586c-9.0234 0-16.359-7.3359-16.359-16.355s7.3359-16.355 16.359-16.355c9.0234 0 16.359 7.3359 16.359 16.355s-7.3359 16.355-16.359 16.355z" />
      <path d="m220.5 81.477c-1.7773 0-3.2344 1.4492-3.2344 3.2305s1.457 3.2305 3.2344 3.2305c1.7891 0 3.2344-1.4492 3.2344-3.2305s-1.4453-3.2305-3.2344-3.2305zm0 19.586c-9.0234 0-16.359-7.3359-16.359-16.355s7.3359-16.355 16.359-16.355c9.0234 0 16.359 7.3359 16.359 16.355s-7.3359 16.355-16.359 16.355z" />
      <path d="m281.24 361.97h-150.82c-17.637 0-31.992-14.348-31.992-31.988v-250.42c0-17.637 14.355-31.988 31.992-31.988h262.92c17.648 0 31.992 14.352 31.992 31.988v125.03c0 3.625-2.9414 6.5625-6.5625 6.5625-3.6211 0-6.5625-2.9375-6.5625-6.5625v-125.03c0-10.398-8.4648-18.863-18.867-18.863h-262.92c-10.402 0-18.867 8.4609-18.867 18.863v250.41c0 10.402 8.4648 18.863 18.867 18.863h150.82c3.625 0 6.5625 2.9414 6.5625 6.5625 0 3.625-2.9375 6.5625-6.5625 6.5625z" />
      <path d="m306.66 211.16c-10.402 0-18.855 8.4609-18.855 18.863v250.41c0 10.402 8.4531 18.859 18.855 18.859h262.93c10.402 0 18.855-8.4531 18.855-18.855v-250.41c0-10.402-8.4531-18.863-18.855-18.863h-262.93zm262.93 301.26h-262.93c-17.637 0-31.98-14.344-31.98-31.98v-250.41c0-17.637 14.344-31.988 31.98-31.988h262.93c17.637 0 31.98 14.348 31.98 31.988v250.41c0 17.637-14.344 31.984-31.98 31.98z" />
      <path d="m595.01 272.29h-313.77c-3.6367 0-6.5625-2.9375-6.5625-6.5625s2.9297-6.5625 6.5625-6.5625h313.77c3.6211 0 6.5625 2.9375 6.5625 6.5625s-2.9375 6.5625-6.5625 6.5625z" />
      <path d="m320.41 231.93c-1.7773 0-3.2383 1.4492-3.2383 3.2305s1.457 3.2305 3.2344 3.2305c1.7891 0 3.2344-1.4492 3.2344-3.2305s-1.4453-3.2305-3.2344-3.2305zm0 19.586c-9.0117 0-16.352-7.3359-16.352-16.355 0-9.0195 7.3359-16.355 16.348-16.355 9.0234 0 16.359 7.3359 16.359 16.355 0 9.0195-7.3359 16.359-16.359 16.355z" />
      <path d="m358.58 231.93c-1.7773 0-3.2266 1.4492-3.2266 3.2305s1.4453 3.2305 3.2227 3.2305c1.7891 0 3.2344-1.4492 3.2344-3.2305s-1.4453-3.2305-3.2344-3.2305zm0 19.586c-9.0117 0-16.352-7.3359-16.352-16.355 0-9.0195 7.3359-16.355 16.348-16.355 9.0234 0 16.359 7.3359 16.359 16.355 0 9.0195-7.3359 16.359-16.359 16.355z" />
      <path d="m396.75 231.93c-1.7773 0-3.2266 1.4492-3.2266 3.2305s1.4453 3.2305 3.2227 3.2305c1.7891 0 3.2344-1.4492 3.2344-3.2305s-1.4453-3.2305-3.2344-3.2305zm0 19.586c-9.0117 0-16.348-7.3359-16.348-16.355 0-9.0195 7.3359-16.355 16.348-16.355 9.0234 0 16.359 7.3359 16.359 16.355 0 9.0195-7.3359 16.359-16.359 16.355z" />
      <path d="m165.63 499.29h69.477v-14.785h-48.125c-3.625 0-6.5625-2.9375-6.5625-6.5625v-45.574c0-3.625 2.9375-6.5625 6.5625-6.5625h4.9805l-18.934-25.777-18.934 25.777h4.9766c3.625 0 6.5625 2.9375 6.5625 6.5625v66.922zm76.039 13.125h-82.602c-3.6211 0-6.5625-2.9258-6.5625-6.5625v-66.922h-11.383c-2.4727 0-4.7383-1.3906-5.8555-3.5977-1.1172-2.207-0.89844-4.8555 0.57031-6.8516l31.902-43.426c1.2305-1.6836 3.1992-2.6758 5.2852-2.6758 2.0859 0 4.0547 0.99219 5.2852 2.6758l31.902 43.426c1.4688 1.9922 1.6875 4.6406 0.57031 6.8516-1.1172 2.207-3.3828 3.5977-5.8555 3.5977h-11.383v32.449h48.125c3.6211 0 6.5625 2.9375 6.5625 6.5625v27.91c0 3.6367-2.9414 6.5625-6.5625 6.5625z" />
      <path d="m471.29 135.89 18.938 25.777 18.945-25.777h-4.9883c-3.625 0-6.5625-2.9375-6.5625-6.5625v-45.574c0-3.625 2.9375-6.5625 6.5625-6.5625h48.137v-14.789h-69.488v66.926c0 3.625-2.9414 6.5625-6.5625 6.5625zm18.938 43.426c-2.0859 0-4.0469-0.99219-5.2891-2.6758l-31.902-43.426c-1.4688-1.9961-1.6875-4.6406-0.57031-6.8477 1.1172-2.207 3.3828-3.5977 5.8555-3.5977h11.383v-66.926c0-3.625 2.9375-6.5625 6.5625-6.5625h82.613c3.625 0 6.5625 2.9375 6.5625 6.5625v27.914c0 3.625-2.9414 6.5625-6.5625 6.5625h-48.137v32.449h11.383c2.4844 0 4.7383 1.3906 5.8555 3.5977 1.1172 2.207 0.89844 4.8555-0.55859 6.8516l-31.902 43.422c-1.2422 1.6836-3.2031 2.6797-5.2969 2.6758z" />
      <path d="m548.63 332.32h-221c-3.625 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h221.01c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9414 6.5625-6.5625 6.5625z" />
      <path d="m548.63 392.35h-221c-3.625 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h221.01c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9414 6.5625-6.5625 6.5625z" />
      <path d="m548.63 452.39h-221c-3.625 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h221.01c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9414 6.5625-6.5625 6.5625z" />
      <path d="m372.38 181.86h-221c-3.6211 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h221c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9375 6.5625-6.5625 6.5625z" />
      <path d="m281.24 241.9h-129.86c-3.6211 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h129.86c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9375 6.5625-6.5625 6.5625z" />
      <path d="m281.24 301.93h-129.86c-3.6211 0-6.5625-2.9375-6.5625-6.5625s2.9414-6.5625 6.5625-6.5625h129.86c3.625 0 6.5625 2.9375 6.5625 6.5625s-2.9375 6.5625-6.5625 6.5625z" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "RedirectManagementIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>

<style scoped>
.svg-icon {
  width: 100%;
}
</style>
