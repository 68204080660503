<template>
  <transition
      v-if="getPermissions(`${item.name}:Module`)"
      name="fade">
    <slot name="side-menu-nav-item">
      <li
          v-if="isVisible"
          class="navigation-item"
          :class="{ minified: isMinified }">
        <router-link
            :to="`/${item.path}`"
            :aria-label="`Link to ${name} module`"
            role="link">
          <div class="icon-container">
            <component
                :is="item.icon"
                :aria-label="`${name} Icon`"
                color="var(--main-brand)" />
          </div>
          <div
              v-if="!isMinified"
              class="menu-label">
            {{ name }}
          </div>
        </router-link>
      </li>
    </slot>
  </transition>
</template>

<script>
import getPermissions from "@/Modules/Core/utils/getPermissions"

export default {
  name: "NavigationItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    isMinified: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isVisible() {
      const { item } = this

      return getPermissions(`${item.name}:Module:canRead`) && item.enabled && item.mainMenu
    },
    name() {
      if (this.item.menuName) {
        return this.item.menuName
      }
      return this.item.name
    },
    scope() {
      if (!this.$store.state.settings.app.scope) {
        return ""
      }
      return this.$store.state.settings.app.scope.get(this.$route.params)
    }
  },
  methods: {
    getPermissions
  }
}
</script>

<style lang="scss" scoped>
.navigation-item {
  padding: 0;

  a {
    text-decoration: none;
    padding: 0 1em;
    border-left: 2px solid transparent;
    transition: var(--main-transition);
    color: #505050e6;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;

    &:focus {
      outline: none;
    }

    .icon-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      background:white;
      border-radius: 50%;
      box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
      height: 2.5em;
      max-height: 2.5em;
      width: 2.5em;
      max-width: 2.5em;

      margin: 0.5em 0.5em 0.5em 0;
      padding: 0.35em;

      ::v-deep .core-icon {
        font-size: 2em;

        svg {
          transform: scale(0.7);
        }
      }
    }
    .menu-label {
      flex: 4;
    }

    &:hover,
    &.router-link-active {
      background: rgba(245, 147, 0, 0.34);
      box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.1);
    }
  }
  &:last-child {
    a {
      border-bottom: 0;
    }
  }

  &.minified {
    a {
      padding-left: 0.5em;
      padding-right: 0;
      padding-bottom: 0;
      justify-content: center;
      .icon.container {
        margin-right: 0;
      }
    }
  }
}
</style>
