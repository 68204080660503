<template>
  <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      class="svg-icon"
      :fill="color"
      viewBox="0 0 64 80"
      style="enable-background:new 0 0 64 64;"
      xml:space="preserve">
    <path
        d="M5,56h25v5.019c0,0.265,0.105,0.52,0.293,0.708c0.188,0.187,0.442,0.292,0.707,0.292c0,0,0,0,0.001,0l28-0.019  C59.553,62,60,61.552,60,61V37c0-2.206-1.794-4-4-4c-1.201,0-2.266,0.542-3,1.382C52.266,33.542,51.201,33,50,33  c-0.732,0-1.409,0.212-2,0.556V3c0-0.552-0.447-1-1-1H12c-0.022,0-0.042,0.011-0.063,0.013c-0.088,0.006-0.173,0.023-0.257,0.051  c-0.032,0.011-0.063,0.019-0.094,0.033c-0.106,0.049-0.207,0.11-0.293,0.196l-7,7C4.207,9.379,4.146,9.48,4.097,9.586  C4.083,9.617,4.075,9.647,4.064,9.679c-0.029,0.084-0.046,0.17-0.052,0.259C4.011,9.96,4,9.979,4,10v45C4,55.552,4.447,56,5,56z   M50,35c1.103,0,2,0.897,2,2v8h2v-8c0-1.103,0.897-2,2-2s2,0.897,2,2v23l-26,0.018V50.5l2-1.501V52h2V25c0-1.103,0.897-2,2-2  s2,0.897,2,2v12v8h2v-8c0-1.103,0.897-2,2-2s2,0.897,2,2v8h2v-8C48,35.897,48.897,35,50,35z M46,33.556  C45.409,33.212,44.732,33,44,33s-1.409,0.212-2,0.556V25c0-2.206-1.794-4-4-4s-4,1.794-4,4v21.497L30.399,49.2  C30.148,49.389,30,49.686,30,50v4H6V11h6c0.553,0,1-0.448,1-1V4h33V33.556z M11,5.414V9H7.414L11,5.414z" />
    <path
        d="M9,14c-0.553,0-1,0.448-1,1v8c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1v-8c0-0.552-0.447-1-1-1H9z M16,22h-6v-6h6V22z" />
    <path
        d="M17,27H9c-0.553,0-1,0.448-1,1v8c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1v-8C18,27.448,17.553,27,17,27z M16,35h-6v-6h6  V35z" />
    <path
        d="M17,40H9c-0.553,0-1,0.448-1,1v8c0,0.552,0.447,1,1,1h8c0.553,0,1-0.448,1-1v-8C18,40.448,17.553,40,17,40z M16,48h-6v-6h6  V48z" />
    <rect
        x="20"
        y="18"
        width="12"
        height="2" />
    <rect
        x="20"
        y="31"
        width="12"
        height="2" />
    <rect
        x="20"
        y="44"
        width="4"
        height="2" />
    <rect
        x="26"
        y="44"
        width="2"
        height="2" />
    <rect
        x="30"
        y="44"
        width="2"
        height="2" />
  </svg>
</template>
<script>
export default {
  name: "OrderManagementIcon",
  props: {
    color: {
      type: String,
      default: "#000000"
    }
  }
}
</script>
<style scoped>
.svg-icon {
  width: 100%;
}
</style>
