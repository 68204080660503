const date = new Date()
const dateFrom = new Date(date.getDate() - 7)
const dateTo = new Date()

export default [
  {
    label: "Rule",
    options: [
      {
        label: "Search 'From URL'",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.fromUrl",
        inputType: "text",
        props: {
          placeholder: "Search by URL/Path"
        }
      },
      {
        label: "Search 'To URL'",
        selectedByDefault: true,
        queryType: "search",
        urlKey: "filter.toUrl",
        inputType: "text",
        props: {
          placeholder: "Search by URL/Path"
        }
      },
      {
        label: "Creation Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.createdAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Creation Date"
        }
      },
      {
        label: "Update Date",
        inputType: "daterange",
        queryType: "search",
        urlKey: "filter.updatedAt",
        defaultValue: [dateFrom, dateTo],
        props: {
          rangeSeparator: "To",
          startPlaceholder: "Start date",
          endPlaceholder: "End date",
          format: "dd/MM/yyyy",
          valueFormat: "yyyy-MM-dd 00:00:00",
          placeholder: "Filter by Update Date"
        }
      },
    ]
  },
]