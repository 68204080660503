export default {
  policies: {
    'default-src': ["'self'", 'https://*.hexa3d.io', 'https://*.h3dstaging.com'],
    'img-src': [
      "'self'",
      'images.ctfassets.net',
      'https://www.google-analytics.com',
      'https://www.google.com',
      'https://www.google.com.au',
      'https://www.googletagmanager.com',
      'https://v2assets.zopim.io',
      'https://tr.snapchat.com',
      'https://www.facebook.com',
      'https://ct.pinterest.com',
      'https://www.pinterest.com',
      'https://network-stg.bazaarvoice.com',
      'https://d.adroll.com',
      'https://dsum-sec.casalemedia.com',
      'https://pixel.rubiconproject.com',
      'https://pixel.advertising.com',
      'https://sync.outbrain.com',
      'https://*.pubmatic.com',
      'https://*.yahoo.com',
      'https://sync.taboola.com',
      'https://eb2.3lift.com',
      'https://x.bidswitch.net',
      'https://idsync.rlcdn.com',
      'https://us-u.openx.net',
      'https://ib.adnxs.com',
      'https://cm.g.doubleclick.net',
      'https://10800822.fls.doubleclick.net',
      'https://sync.mathtag.com',
      'https://match.adsrvr.org',
      'https://rc.rlcdn.com',
      'https://edge.curalate.com',
      'https://bat.bing.com',
      'https://cdn.feedbackify.com',
      'https://tag.yieldoptimizer.com',
      'https://*.bazaarvoice.com',
      'https://www.michaelhill.com',
      'https://www.michaelhill.com.au',
      'https://www.michaelhill.co.nz',
      'https://www.michaelhill.ca',
      'https://optimize.google.com',
      'https://*.zip.co',
      'https://zip.co',
      'https://*.zipmoney.com.au',
      'https://demo4.paasweb.co.il',
      'https://*.inside-graph.com',
      'https://*.powerfront.com',
      'https://s.yimg.com',
      'https://*.adroll.com',
      'https://adroll.com',
      'https://*.clarity.ms',
      'https://*.analytics.google.com',
      'https://analytics.google.com',
      'https://*.barilliance.com',
      'https://barilliance.com',
      'https://*.s3.amazonaws.com',
      'https://*.amazonaws.com',
      'https://amazonaws.com',
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
    ],
    'style-src': [
      "'self'",
      'https://www.googleapis.com',
      'https://fonts.googleapis.com',
      'https://www.googletagmanager.com',
      "'unsafe-inline'",
      'https://s3.amazonaws.com/static.barilliance.com/owl-carousel/owl.carousel.css',
      'https://s3.amazonaws.com/static.barilliance.com/owl-carousel/owl.theme.css',
      'https://optimize.google.com',
      'https://www.michaelhill.com.au',
      'https://www.michaelhill.co.nz',
      'https://www.michaelhill.ca',
      'https://*.bazaarvoice.com',
      'https://*.zip.co',
      'https://*.zipmoney.com.au',
      'https://demo4.paasweb.co.il',
      'https://*.inside-graph.com',
      'https://*.powerfront.com',
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
    ],
    'font-src': ["'self'", 'data: localhost', 'https://fonts.gstatic.com', 'https://*.inside-graph.com'],
    'media-src': [
      "'self'",
      'https://player.vimeo.com/',
      'https://static.zdassets.com',
      'https://*.akamaized.net',
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
      'https://*.curalate.com',
    ],
    'script-src': [
      "'self'",
      "'unsafe-inline'", // required for inline scripts in GTM container
      "'unsafe-eval'", // required for curalate in GTM (instagram feed)
      'https://www.google-analytics.com',
      'https://ssl.google-analytics.com',
      'https://www.googletagmanager.com',
      'www.googleadservices.com',
      'https://*.hotjar.com',
      'https://sc-static.net',
      'https://unpkg.com',
      'https://*.bazaarvoice.com',
      'https://analytics-static.ugc.bazaarvoice.com',
      'https://static.zdassets.com',
      'https://connect.facebook.net',
      'https://googleads.g.doubleclick.net',
      'https://s.pinimg.com',
      'https://analytics.tiktok.com',
      'https://s.adroll.com',
      'https://cdn.rudderlabs.com',
      'http://edge.curalate.com',
      'https://d.adroll.com',
      'https://cdn.feedbackify.com',
      'https://www1.feedbackify.com',
      'https://ajax.googleapis.com',
      'https://s3.amazonaws.com',
      'https://bat.bing.com',
      'https://*.barilliance.com',
      'https://www.barilliance.net',
      'https://www.google.com',
      'https://www.googleoptimize.com',
      'https://www.googleanalytics.com',
      'https://optimize.google.com',
      'https://*.michaelhill.com.au',
      'https://*.michaelhill.ca',
      'https://*.michaelhill.co.nz',
      'https://*.zip.co',
      'https://*.zipmoney.com.au',
      'https://demo4.paasweb.co.il',
      'https://*.inside-graph.com',
      'https://*.powerfront.com',
      'https://s.yimg.com',
      'https://*.adroll.com',
      'https://adroll.com',
      'https://*.clarity.ms',
      'https://*.analytics.google.com',
      'https://analytics.google.com',
      'https://d3aq2u4yw77ivo.cloudfront.net', // brauz reserve in store
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
    ],
    'connect-src': [
      "'self'",
      'https://www.google-analytics.com',
      'https://www.googleapis.com',
      'https://maps.googleapis.com',
      'https://ekr.zdassets.com',
      'https://michaelhill.zendesk.com',
      'wss://widget-mediator.zopim.com',
      'https://ct.pinterest.com',
      'https://apps.bazaarvoice.com',
      'https://api.rudderlabs.com',
      'https://michaelhill-dataplane.rudderstack.com',
      'https://analytics.tiktok.com',
      'https://stats.g.doubleclick.net',
      'https://*.hotjar.io',
      'https://*.hotjar.com',
      'wss://*.hotjar.com',
      'https://in.hotjar.com',
      'https://edge.curalate.com',
      'https://tr.snapchat.com',
      'https://bat.bing.com',
      'https://api.pinpiaa.com',
      'https://d.adroll.com',
      'https://*.bazaarvoice.com',
      'https://*.zip.co',
      'https://*.zipmoney.com.au',
      'https://demo4.paasweb.co.il',
      'https://*.inside-graph.com',
      'wss://*.inside-graph.com',
      'https://*.powerfront.com',
      'https://s.yimg.com',
      'https://*.adroll.com',
      'https://adroll.com',
      'https://*.clarity.ms',
      'https://*.fls.doubleclick.net',
      'https://fls.doubleclick.net',
      'https://*.analytics.google.com',
      'https://analytics.google.com',
      'https://*.barilliance.com',
      'https://www.barilliance.net',
      'https://brauz-api-netlify.netlify.app',
      'https://*.michaelhill.com.au',
      'https://*.michaelhill.ca',
      'https://*.michaelhill.co.nz',
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
    ],
    'frame-ancestors': ["'self'", 'https://*.hexa3d.io', 'https://*.h3dstaging.com'],
    'object-src': ["'none'"],
    'frame-src': [
      'https://bid.g.doubleclick.net',
      'https://*.fls.doubleclick.net',
      'https://*.doubleclick.net',
      'https://tr.snapchat.com',
      'https://vars.hotjar.com',
      'https://www.facebook.com',
      'https://www.pinterest.com',
      'https://www.pinterest.com.au',
      'https://optimize.google.com',
      'https://www.youtube.com',
      'https://widgets.shophumm.com.au',
      'https://*.zipmoney.com.au',
      'https://widgets.partpay.co.nz',
      'https://*.bazaarvoice.com',
      'https://demo4.paasweb.co.il',
      'https://*.inside-graph.com',
      'https://*.powerfront.com',
      'https://www.barilliance.net',
      'https://reserve-in-store-michael-hill-ca.netlify.app',
      'https://reserve-in-store-michael-hill-nz.netlify.app',
      'https://reserve-in-store-michael-hill-au.netlify.app',
      'https://*.michaelhill.com.au',
      'https://*.michaelhill.ca',
      'https://*.michaelhill.co.nz',
      'https://*.hexa3d.io',
      'https://*.h3dstaging.com',
    ],
  },
};