import SiteManagementStatusColumn from "./SiteManagementStatusColumn";
import SiteManagementColorColumn from "./SiteManagementColorColumn";

const defaultColumns = [
    {
      prop: "id",
      label: "ID"
    },
    {
      prop: "title",
      label: "Site"
    },
    {
      prop: "url",
      label: "Url",
    },
    {
      prop: "color",
      label: "Site Color",
      Component: SiteManagementColorColumn
    },
    {
      prop: "active",
      label: "Status",
      Component: SiteManagementStatusColumn
    }
  ]

export default defaultColumns