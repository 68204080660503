export default {
  fields: {
    siteDetails: {
      title: {
        type: "text",
        value: null,
        label: "Site Title",
        placeholder: "ie. Michael Hill AU",
        validation: [{required: true, message: "Please enter site title", trigger: "blur"}]
      },
      url: {
        type: "text",
        value: null,
        label: "Site Url",
        placeholder: "ie. https://www.michaelhill.com.au",
        validation: [{required: true, message: "Please enter a url for this site", trigger: "blur"}]
      },
      color: {
        type: "color-picker",
        value: null,
        label: "Color",
        placeholder: "Pick a color",
        validation: [{ required: true, message: "Please select a color", trigger: "blur" }]
      },
      active: {
        type: "switch",
        label: "Activate Site",
      }
    },
  }
}
