<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="computedFormData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { CspConfiguration } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"

  export default {
    name: "CspConfigurationForm",
    props: {
      dialogProps: { type: Object, required: true },
      formData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig
      }
    },
    computed: {
      computedFormData() {
        const result = this.formData
        if (result?.active) {
          result.active = result.active === "1";
        }
        return result
      },
      mode() {
        return this.dialogProps.mode
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()


          if (this.mode === "create") {
            await CspConfiguration.api().create(flatFormData)
          } else if (this.mode === "edit") {

            flatFormData.id = this.formData.id

            await CspConfiguration.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your CSP Configuration details have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>