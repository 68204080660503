<template>
  <div class="form">
    <CoreFormBuilderV2
        :data="computedFormData"
        :form-config="formConfig"
        :is-loading="isLoading"
        :mode="mode"
        @onSubmit="onSubmit"
        @onCancel="onCancel" />
  </div>
</template>

<script>
  import { SitePreference } from "@/Modules/OdysseyModels"
  import formConfig from "./formConfig"

  export default {
    name: "SitePreferencesForm",
    props: {
      dialogProps: { type: Object, required: true },
      sitePreferenceData: {
        type: Object, default: () => ({})
      }
    },
    data() {
      return {
        isLoading: false,
        formConfig
      }
    },
    computed: {
      computedFormData() {
        const fieldData = this.sitePreferenceData

        if (fieldData.engraving) {
          fieldData.engraving = fieldData.engraving === "1";
        }
        if (fieldData.internationalShipping) {
          fieldData.engraving = fieldData.internationalShipping === "1";
        }
        if (fieldData.clickAndCollect) {
          fieldData.engraving = fieldData.clickAndCollect === "1";
        }
        if (fieldData.specialOrder) {
          fieldData.engraving = fieldData.specialOrder === "1";
        }
        if (fieldData.redisCacheLifetime) {
          fieldData.redisCacheLifetime = parseInt(fieldData.redisCacheLifetime)
        }
        return fieldData
      },
      mode() {
        return this.dialogProps.mode
      }
    },
    methods: {
      onCancel() {
        this.dialogProps.closeDialog()
      },
      async onSubmit(Form) {
        try {
          const flatFormData = Form.getFlatData()

          if (this.mode === "create") {
            await SitePreference.api().create(flatFormData)
          } else if (this.mode === "edit") {
            flatFormData.id = this.sitePreferenceData.id
            await SitePreference.api().update(flatFormData)
          }

          const notificationsPayload = {
            component: "Toast",
            type: "success",
            title: "Thank You",
            message: "Your Site Preferences have been successfully updated.",
            visible: true,
            decay: 3500
          }

          this.$store.dispatch("core/notifications/addToNotifications", notificationsPayload)

          this.dialogProps.closeDialog()
        } catch (error) {
          Form.setSubmitting(false)
          // @todo: handle error
        }
      }
    }
  }
</script>