// import TheStylex from "@/Modules/TheStylexBase/config"
// import Lightwerk from "@/Modules/LightwerkBase/config"
// import Otocare from "@/Modules/OtocareBase/config"
// import JKWMemberPortal from "@/Modules/JKWMemberPortalBase/config"
// import CaneToadEquipAdmin from '@/Modules/CaneToadEquipAdminBase/config
import MHJConfigurator from '@/Modules/MHJConfiguratorBase/config'
// const exports = {
//   // TheStylex: TheStylex,
//   // Lightwerk: Lightwerk,
//   // Otocare: Otocare,
//   // JKWMemberPortal: JKWMemberPortal
//   CaneToadEquipAdmin: CaneToadEquipAdmin
// }

export default MHJConfigurator
// const environment = process.env.VUE_APP_BASE_PATH
//
// export default exports[baseApp]
