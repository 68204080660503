<template>
  <fragment>
    <CoreScreen
        card
        class="redirectManagement-wrapper"
        header="Redirect Management"
        icon="RedirectManagementIcon"
        :create-button="{ label: 'Create Redirect', onCreate: createRedirectManagement }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Redirect } from "@/Modules/OdysseyModels"
export default {
  name: "RedirectManagement",
  methods: {
    createRedirectManagement() {
      new Redirect().Actions.openRedirectDialog()
    }
  }
}
</script>
