<template>
  <div class="SitePreferencesManagementView-container">
    Manager goes here ...
  </div>
</template>

<script>
export default {
  name: "SitePreferencesManagementView"
}
</script>

<style lang="scss" scoped>
</style>