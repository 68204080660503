import CspConfigurationTableView from "./views/CspConfigurationTableView"

const routes = [
  {
    path: "",
    name: "cspConfiguration-list-view",
    component: CspConfigurationTableView
  }
]

export default routes