<template>
  <CoreDialog
      :title="title"
      :loading="isLoading"
      :dialog-props="dialogProps"
      mode-switcher-enabled>
    <template slot="content">
      <div class="csp-rules-container">
        <h3>CSP Configuration</h3>
        <!-- eslint-disable-next-line -->
        <textarea class="form-control">{{ cspRules }}</textarea>

        <h3>Policy list grouped by domains</h3>
        <!-- eslint-disable-next-line -->
        <textarea class="form-control">{{ groupedRules }}</textarea>
        <el-button
            type="success"
            @click="onImportRules">
          Import CSP Configuration into Database
        </el-button>
      </div>
    </template>
  </CoreDialog>
</template>

<script>
import {CspConfiguration} from "@/Modules/OdysseyModels"
import mockCspRules from "@/Modules/CspConfiguration/mockData/mockCspRules";

export default {
  name: "CspConfigurationDialog",
  props: {
    id: { type: [String, Number], default: null },
    closeDialog: { type: Function, required: true },
    dialogProps: { type: Object, required: true },
  },
  data() {
    return {
      isLoading: false,
      cspRules: mockCspRules
    }
  },
  computed: {
    cspConfigurationData() {
      return CspConfiguration.find(this.id)
    },
    mode() {
      return this.dialogProps.mode
    },
    title() {
      return "Currently Active CSP Configuration"
    },
    groupedRules() {

      // group rules by their appearance (img-src, style-src, font-src, media-src, script-src)
      const groups = Object.keys(this.cspRules.policies)

      // create a filtered list of domains and their group, add group to existing domains
      const domainList = groups.reduce((acc, group) => {
        const domains = this.cspRules.policies[group].map(domain => {
          return {
            domain,
            group
          }
        })

        return [...acc, ...domains]
      }, [])

      // check domainList for duplicate domains, if found, add group to existing domain
      const uniqueDomains = domainList.reduce((acc, domain) => {
        const existingDomain = acc.find(item => item.domain === domain.domain)

        if (existingDomain) {
          existingDomain.group = `${existingDomain.group}, ${domain.group}`
        } else {
          acc.push(domain)
        }

        return acc
      }, [])

      return uniqueDomains
    }
  },
  methods: {
    async onImportRules() {
      this.isLoading = true
      const response = await CspConfiguration.api().importConfiguration(this.groupedRules)
      if (response.response?.data?.meta?.count) {
        this.$notify({
          title: "Success",
          message: `Successfully imported ${response.response.data.meta.count} rules`,
          type: "success"
        })
        await CspConfiguration.api().fetchAll()
      } else {
        this.$notify({
          title: "Error",
          message: "Something went wrong",
          type: "error"
        })
      }
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.csp-rules-container {
  textarea {
    background: lightgoldenrodyellow;
    padding: var(--padding-m);
    font-size: 13px;
    width: 100%;
    min-height: 80vh;
    border: 1px solid var(--main-border-color);
    border-radius: var(--border-radius-xs);

  }
}
</style>