export default {
  fields: {
    ContentSecurityPolicy: {
      violation: {
        _options: { defaultFieldWidth: 12, tab: 2 },
        width: 24,
        type: "textarea",
        value: null,
        props: {
          readonly: true,
          class: 'alert-warning'
        },
        label: "CSP Violation (read only)",
        placeholder: "http://",
      },
      rule: {
        _options: { defaultFieldWidth: 12, tab: 2 },
        width: 24,
        type: "text",
        value: null,
        label: "CSP Rule",
        placeholder: "http://",
        validation: [{required: true, message: "Please enter a URL", trigger: "blur"}]
      },
      cspType: {
        type: "select",
        label: "CSP Type",
        validation: [{required: true, message: "Please select a CSP Type", trigger: "blur"}],
        props: {
          multiple: true
        },
        options: [
            // create options from this 'connect-src','img-src','script-src','style-src','media-src','object-src','frame-src','frame-ancestors','default-src'
            {label: "connect-src", value: "connect-src"},
            {label: "img-src", value: "img-src"},
            {label: "script-src", value: "script-src"},
            {label: "style-src", value: "style-src"},
            {label: "media-src", value: "media-src"},
            {label: "object-src", value: "object-src"},
            {label: "frame-src", value: "frame-src"},
            {label: "frame-ancestors", value: "frame-ancestors"},
            {label: "default-src", value: "default-src"},
        ],
      },
      notes: {
        width: 24,
        type: "textarea",
        value: null,
        label: "Notes",
        placeholder: "",
      },
    },
    RuleSettings: {
      site: {
        type: "entity-select",
        props: {
          entity: "Site",
          filterable: true,
          clearable: true,
        },
        label: "Select Site",
        value: null,
        validation: [{required: true, message: "Please select a site", trigger: "blur"}]
      },
      status: {
        type: "select",
        props: {
          filterable: true
        },
        value: null,
        label: "What is the current status of this rule?",
        options: [
          {label: "New (reported by Sentry)", value: "new"},
          {label: "Under Review", value: "under review"},
          {label: "Published", value: "published"},
          {label: "Unpublished", value: "unpublished"}
        ]
      },
      active: {
        type: "switch",
        label: "Activate/Deactivate Rule",
      }
    }
  }
}
