import CspConfigurationStatusColumn from "./CspConfigurationStatusColumn";
import CspConfigurationActiveColumn from "./CspConfigurationActiveColumn";
import CspConfigurationGroupColumn from "./CspConfigurationGroupColumn";

const defaultColumns = [
  {
    prop: "rule",
    label: "Rule",
  },
  {
    prop: "cspType",
    label: "Group",
    Component: CspConfigurationGroupColumn,
  },
  {
      prop: "site",
      label: "Site"
    },
  {
      prop: "Created",
      label: "createdAt",
    },
    {
      prop: "status",
      label: "Status",
      Component: CspConfigurationStatusColumn
    },
    {
      prop: "active",
      label: "Active",
      Component: CspConfigurationActiveColumn
    }
  ]

export default defaultColumns