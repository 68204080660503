import CspConfigurationDialog from "./CspConfigurationDialog/CspConfigurationDialog"
import CspCurrentPolicyDialog from "./CspCurrentPolicyDialog/CspCurrentPolicyDialog"
export default [
  {
    name: "CspConfigurationDialog",
    Component: CspConfigurationDialog,
    permissions: "CspConfiguration:CspConfiguration:canRead"
  },
  {
    name: "CspCurrentPolicyDialog",
    Component: CspCurrentPolicyDialog,
    permissions: "CspConfiguration:CspConfiguration:canRead"
  }
]