<template>
  <fragment>
    <CoreScreen
        card
        class="siteManagement-wrapper"
        header="SiteManagement"
        icon="SiteManagementIcon"
        :create-button="{ label: 'Create Site', onCreate: createSite }">
      <router-view />
    </CoreScreen>
  </fragment>
</template>

<script>
import { Site } from "@/Modules/OdysseyModels"
export default {
  name: "SiteManagement",
  methods: {
    createSite() {
      new Site().Actions.openSiteDialog()
    }
  }
}
</script>
